interface ErrorResponse {
    response: Object
}

export function handleValidationErrors(error: ErrorResponse, setErrors: Function = () => {}) {   
    const response = error?.response;
    const validationErrors = response?._data?.extra;
    const errorMessages = response?._data?.message;
    
    if (validationErrors && Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors?.fields);
    } else { 
        setErrors({general: errorMessages});
    }
}